<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA'"
        class="qrcode-wrap"
        :style="{display: showQrScaner}"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <div class="input-content-wrap">
        <v-row class="pb-0">
          <v-col
            class="pb-0 pt-0"
            cols="12"
          >
            <v-autocomplete
              id="input-pcode"
              ref="refPcode"
              v-model="selectedPCode"
              :items="productionInfoList"
              label="품번"
              :item-text="getText"
              item-value="품번"
              auto-select-first
              :disabled="isUpdateMode"
              @change="onPCodeChange"
              @keyup.enter.prevent="onEnterPcode"
            />
          </v-col>
        </v-row>
        <v-row
          class="mt-0"
          dense
        >
          <v-col
            class="pb-0 pt-0"
            :cols="$store.getters.getMobileModel !== 'PDA' ? 7 : 8"
          >
            <v-text-field
              id="input-qrkey"
              v-model="qrKey"
              label="파레트번호"
              :disabled="isUpdateMode"
              placeholder="QR 스캔"
              clearable
              autocomplete="off"
              @keyup.enter.prevent="onEnterQrCode"
            />
          </v-col>
          <v-col
            v-if="$store.getters.getMobileModel !== 'PDA'"
            class="pb-0 pt-4 pl-0"
            cols="1"
          >
            <v-icon
              @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
            >
              mdi-camera
            </v-icon>
          </v-col>
          <v-col
            class="pb-0 pt-0"
            cols="4"
          >
            <v-text-field
              id="input-qty"
              v-model="qty"
              label="기초재고"
              placeholder="수량"
              type="number"
              clearable
              autocomplete="off"
              @keyup.enter.prevent="onEnterQty"
            />
          </v-col>
        </v-row>
        <v-row
          class="pb-0 mb-0 pt-0"
          dense
        >
          <v-col
            class="pb-0 pt-0"
            cols="6"
          >
            <v-text-field
              id="input-date"
              v-model="effectiveDate"
              v-mask="'####-##-##'"
              label="유효기간"
              placeholder="####-##-##"
              clearable
              autocomplete="off"
              @keyup.enter.prevent="onDateInput"
            />
          </v-col>
          <v-col
            class="pb-0 pt-0"
            cols="6"
          >
            <v-text-field
              id="input-lot-no"
              v-model="inputLotNo"
              label="Lot번호"
              autocomplete="off"
              @keyup.enter.prevent="onEnterLotNo"
            />
          </v-col>
        </v-row>
        <v-row
          class="pb-0 mt-0 pt-0"
          dense
        >
          <v-col
            class="pb-0 pt-0"
            cols="12"
          >
            <v-autocomplete
              id="input-location"
              v-model="selectedLocation"
              :items="locationLookup"
              item-text="장소명"
              item-value="장소코드"
              placeholder="입고저장장소"
              label="입고저장장소"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="mt-0 pt-0 mb-1"
            cols="12"
          >
            <v-btn
              width="100%"
              color="primary"
              @click="onClickSave"
            >
              기초재고 저장
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div
        class="data-grid"
        style="margin-top:6px; background-color:white;"
      >
        <dx-data-grid
          ref="refProductionReceiptGrid"
          :data-source="productionReceiptInfo"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="gridHeightSub"
          :focused-row-enabled="true"
          key-expr="seqId"
          :focused-row-key.sync="focusedRowKey"
          @focused-row-changed="onSelectionChanged"
          @toolbar-preparing="onToolbarPreparing"
          @row-dbl-click="onRowDblClick"
          @row-removed="onRowRemoved"
        >
          <DxColumnFixing :enabled="true" />
          <DxColumn
            :allow-editing="false"
            caption="번호"
            data-field="입고키"
            :min-width="60"
          />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
            :min-width="100"
          />
          <DxColumn
            :allow-editing="false"
            caption="수량"
            data-field="수량"
            :min-width="60"
            format="#,##0"
          />
          <DxColumn
            :allow-editing="false"
            caption="품명"
            data-field="조인품명"
            :min-width="150"
          />
          <DxSummary>
            <DxTotalItem
              value-format="#,##0"
              show-in-column="품번"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
            show-scrollbar="always"
            :use-native="true"
          />
          <dx-sorting mode="multiple" />
          <template #grid-title>
            <div
              class="toolbar-label"
              style="font-size:1.1rem;margin-left:0px;"
            >
              <b>기초재고</b>
            </div>
          </template>
        </dx-data-grid>
      </div>
    </v-container>
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
import DxDataGrid, { DxScrolling, DxColumn, DxPaging, DxSorting, DxColumnFixing, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'

export default {
  name: 'ProductionReceipt',
  components: {
    Loading,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxDataGrid,
    DxColumnFixing,
    DxSummary,
    DxTotalItem,
    QrCodeScanComponent
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      selectedPCode: null,
      productionInfoList: [],
      companyInfoList: [],
      selectedCompany: null,
      qrKey: null,
      qty: null,
      productionReceiptInfo: null,
      gridHeightSub: null,
      focusedRowKey: null,
      today: AppLib.getToday(),
      isUpdateMode: false,
      selectedRow: null,
      locationLookup: null,
      selectedLocation: null,
      // model date
      effectiveDate: null,
      // lot no
      inputLotNo: null,
      // qrcode scan
      showQrScaner: 'none'
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.locationLookup = this.$store.getters.getLocation
    this.selectedLocation = this.locationLookup[0].장소코드
    this.refreshProdction()
    this.refreshProductionReceipt()
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.gridHeightSub = this.$Q('html').height() - this.$Q('.input-content-wrap').height() - 56
    this.handleResize()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    handleResize () {
    },
    initInput () {
      setTimeout(() => {
        this.$Q('#input-pcode').focus()
      }, 100)
      if (this.isUpdateMode) {
        this.qrKey = null
      }
      this.selectedPCode = null
      this.qty = null
      this.isUpdateMode = false
      this.inputLotNo = null
      this.effectiveDate = null
    },
    getText (row) {
      return `${row.품번} (${row.품명})`
    },
    onClickSave () {
      if (this.qty === null) {
        this.$snotify.error('재고 수량을 입력하여 주세요')
        this.$Q('#input-qty').focus()
        return
      }
      if (this.selectedPCode === null) {
        this.$snotify.error('제품 품번을 입력하여 주세요')
        this.$Q('#input-pcode').focus()
        return
      }
      if (this.qrKey === null) {
        this.$snotify.error('파레트 번호를 입력하여 주세요')
        this.$Q('#input-qrkey').focus()
        return
      }
      if (!this.isUpdateMode && this.$_.findIndex(this.productionReceiptInfo, { 품번: this.selectedPCode, 입고키: parseInt(this.qrKey) }) >= 0) {
        this.$snotify.info('해당 파레트 번호와 품번은 이미 등록되어 있습니다.')
        return
      }
      let temp = null
      let stockSameCode = `${this.effectiveDate.substring(0, 4)}${this.effectiveDate.substring(5, 7)}${this.effectiveDate.substring(8, 10)}_${this.inputLotNo}`
      stockSameCode = stockSameCode.toUpperCase()
      if (this.isUpdateMode === false) {
        temp = {
          발생일자: '2021-01-01',
          입출고구분: ConstDef.자재입고,
          품번: this.selectedPCode,
          수량: this.qty,
          입고키: parseInt(this.qrKey),
          품목유형: ConstDef.구매품,
          유효일: this.effectiveDate,
          로트번호: this.inputLotNo,
          동종재고코드: stockSameCode,
          장소위치코드: this.selectedLocation,
          createid: this.userInfo.userName,
          updateid: this.userInfo.userName,
          createtime: AppLib.getNow(),
          taskInputEnd: true
        }
        this.$_sp.runInsertAndFindSqlProc('창고수불', ['품번', '입고키', 'createid'], ['seqId'], temp)
          .then((data) => {
            this.$snotify.info('저장 되었습니다.', { timeout: 700 })
            temp.seqId = this.$_sp.MakeModel(data)[0].seqId
            this.focusedRowKey = temp.seqId
            temp.조인품명 = this.$_.findWhere(this.productionInfoList, { 품번: this.selectedPCode }).품명
            this.productionReceiptInfo.push(temp)
            this.$refs.refProductionReceiptGrid.instance.refresh()
            this.initInput()
          })
          .catch(error => {
            this.initInput()
            if (error.response.status === ConstDef.GENERRAL_ERROR) {
              this.$snotify.error(error.response.data.message)
            } else {
              this.$snotify.error(`에러코드 : ${error.response.status}`)
            }
          })
      } else {
        temp = {
          seqId: this.selectedRow.seqId,
          수량: this.qty,
          입고키: parseInt(this.qrKey),
          유효일: this.effectiveDate,
          로트번호: this.inputLotNo,
          동종재고코드: stockSameCode,
          장소위치코드: this.selectedLocation,
          updateid: this.userInfo.userName,
          updatetime: AppLib.getNow()
        }
        this.$_sp.runUpdateSqlProc('창고수불', ['seqId'], null, [temp])
          .then((data) => {
            this.$snotify.info('저장 되었습니다.', { timeout: 700 })
            this.selectedRow.수량 = this.qty
            this.selectedRow.입고키 = parseInt(this.qrKey)
            this.selectedRow.유효일 = this.effectiveDate
            this.selectedRow.로트번호 = this.inputLotNo
            this.$refs.refProductionReceiptGrid.instance.refresh()
            this.initInput()
          })
          .catch(error => {
            this.initInput()
            if (error.response.status === ConstDef.GENERRAL_ERROR) {
              this.$snotify.error(error.response.data.message)
            } else {
              this.$snotify.error(`에러코드 : ${error.response.status}`)
            }
          })
      }
    },
    // datepicker
    onDateInput () {
      this.$Q('#input-lot-no').focus()
    },
    onEnterLotNo (e) {
      // this.onClickSave()
    },
    onEnterQrCode (e) {
      if (this.qrKey === null) return
      let split = null
      split = AppLib.base64DecodeSubstring(this.qrKey)
      this.goodsInputListInfo = null
      this.qrKey = split
      this.$Q('#input-qty').focus()
      console.log(this.qrKey)
    },
    onEnterQty (e) {
      if (this.qty !== null && this.qty.length > 0) {
        this.$Q('#input-date').focus()
      }
    },
    onEnterPcode (e) {
      const val = AppLib.base64DecodeSubstring(this.$Q('#input-pcode').val())
      this.$Q('#input-pcode').text(val)
    },
    onPCodeChange (e) {
      setTimeout(() => {
        this.showQrScaner = 'none'
        this.$Q('#input-qrkey').focus()
        this.$Q('.v-autocomplete__content').css('display', 'none')
      }, 300)
    },
    onPCodeScan (result) {
      this.qrKey = result
      this.onEnterQrCode(null)
    },
    onRowDblClick (row) {
      this.isUpdateMode = true
      this.selectedPCode = row.data.품번
      this.qty = row.data.수량
      this.qrKey = row.data.입고키
      this.inputLotNo = row.data.로트번호
      if (AppLib.isNumeric(row.data.유효일)) {
        row.data.유효일 = AppLib.formatDateFromTimeStamp(row.data.유효일)
      }
      this.effectiveDate = row.data.유효일
      this.selectedRow = row.data
    },
    onRowRemoved (row) {
      this.$_sp.runDeleteSqlProc('창고수불', [{ seqId: row.data.seqId }])
        .then((data) => {
          this.$snotify.info('삭제 되었습니다.', { timeout: 700 })
          this.initInput()
        })
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRow = e.row.data
      }
    },
    onToolbarPreparing   (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: 'grid-title'
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'always',
          options: {
            icon: 'edit',
            text: '수정',
            focusStateEnabled: true,
            onClick: (args) => {
              if (this.selectedRow === null) {
                this.$snotify.error('선택된 행이 없습니다')
                return
              }
              this.onRowDblClick({ data: this.selectedRow })
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'always',
          options: {
            icon: 'refresh',
            text: '수정취소',
            focusStateEnabled: true,
            onClick: (args) => {
              this.initInput()
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'always',
          options: {
            icon: 'trash',
            text: '삭제',
            focusStateEnabled: true,
            onClick: (args) => {
              if (this.selectedRow === null) {
                this.$snotify.error('선택된 행이 없습니다')
                return
              }
              const rowIndex = this.$refs.refProductionReceiptGrid.instance.getRowIndexByKey(this.selectedRow.seqId)
              this.$refs.refProductionReceiptGrid.instance.deleteRow(rowIndex)
            }
          }
        }
      )
    },
    refreshProdction () {
      this.$_sp.runNoEncodeFindProc('spFindAllProduct', {})
        .then((data) => {
          this.productionInfoList = this.$_sp.MakeModel(data)
          this.initInput()
        })
        .catch(error => {
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshProductionReceipt () {
      this.$_sp.runNoEncodeFindProc('spFindAllBasicInventoryByDate', { 발생일: '2021-01-01' })
        .then((data) => {
          this.productionReceiptInfo = this.$_sp.MakeModel(data)
          console.log(this.productionReceiptInfo)
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
